import plugin_vue3_A0OWXRrUgq from "/Users/kerem/Documents/repo/atelier-materica-mente/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/kerem/Documents/repo/atelier-materica-mente/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/kerem/Documents/repo/atelier-materica-mente/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/kerem/Documents/repo/atelier-materica-mente/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/Users/kerem/Documents/repo/atelier-materica-mente/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/kerem/Documents/repo/atelier-materica-mente/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/Users/kerem/Documents/repo/atelier-materica-mente/node_modules/nuxt/dist/app/plugins/payload.client.js";
import css_class_merge_gQgRnVn2YT from "/Users/kerem/Documents/repo/atelier-materica-mente/plugins/css-class-merge.js";
import gtag_client_R70iZRv6jK from "/Users/kerem/Documents/repo/atelier-materica-mente/plugins/gtag.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  css_class_merge_gQgRnVn2YT,
  gtag_client_R70iZRv6jK
]