import { default as _91title_93xNs9YkGviMMeta } from "/Users/kerem/Documents/repo/atelier-materica-mente/pages/[title].vue?macro=true";
import { default as indextsB5UjwAWtMeta } from "/Users/kerem/Documents/repo/atelier-materica-mente/pages/index.vue?macro=true";
export default [
  {
    name: _91title_93xNs9YkGviMMeta?.name ?? "title",
    path: _91title_93xNs9YkGviMMeta?.path ?? "/:title()",
    meta: _91title_93xNs9YkGviMMeta || {},
    alias: _91title_93xNs9YkGviMMeta?.alias || [],
    redirect: _91title_93xNs9YkGviMMeta?.redirect || undefined,
    component: () => import("/Users/kerem/Documents/repo/atelier-materica-mente/pages/[title].vue").then(m => m.default || m)
  },
  {
    name: indextsB5UjwAWtMeta?.name ?? "index",
    path: indextsB5UjwAWtMeta?.path ?? "/",
    meta: indextsB5UjwAWtMeta || {},
    alias: indextsB5UjwAWtMeta?.alias || [],
    redirect: indextsB5UjwAWtMeta?.redirect || undefined,
    component: () => import("/Users/kerem/Documents/repo/atelier-materica-mente/pages/index.vue").then(m => m.default || m)
  }
]