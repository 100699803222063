import { twMerge } from 'tailwind-merge';

export default defineNuxtPlugin(nuxtApp => {
  const $cl = (base, dynamic) => {
    return twMerge(
      // Return base classes
      base +
      // Add space between base classes and dynamic classes
      ' ' +
      // If the dynamic class resolve as true, add it to the base class, otherwise ignore it and return the accumulator.
      Object.keys(dynamic).reduce((acc, k) =>
        dynamic[k] ? acc + ' ' + k : acc,
        ''
      )
    );
  };

  nuxtApp.provide('cl', $cl)
})