export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"title","name":"title","content":"Atelier materica-mente"},{"hid":"url","name":"url","content":"https://materica-mente.ch"},{"hid":"description","name":"description","content":"Swiss artist based in Bellinzona, passionate about abstract art. Discover my vibrant and creative world on canvas."},{"hid":"keywords","name":"keywords","content":"swiss, artist, bellinzona, abstract, art, creative, process, mixed, media, self-taught, artist, paintings, artworks"},{"hid":"web-author","name":"web-author","content":"Kerem Türkyilmaz"},{"hid":"copyright","name":"copyright","content":"Kerem Türkyilmaz"},{"hid":"language","name":"language","content":"english"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:image","name":"og:image","property":"og:image","content":"https://materica-mente.ch/atelier-materica-mente.png"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"Atelier materica-mente"},{"hid":"og:title","name":"og:title","property":"og:title","content":"Atelier materica-mente"},{"hid":"og:url","name":"og:url","property":"og:url","content":"https://materica-mente.ch"},{"hid":"og:image:secure","name":"og:image:secure","property":"og:image:secure","content":"https://materica-mente.ch/atelier-materica-mente.png"},{"hid":"google-site-verification","name":"google-site-verification","property":"google-site-verification","content":"3_nte7dGlwUCXiSi8U8jCXU6sRbGvKa67scBIM7Ww0I"}],"link":[{"rel":"stylesheet","href":"/fonts/fonts.css"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"Atelier materica-mente"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null